<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card px-3" :loading="loading">
        <v-overlay color="white" :absolute="true" :value="loading"> </v-overlay>
        <v-tabs v-model="tab" class="mb-5">
          <v-tab href="#tab-1">
            <v-icon class="mr-2">mdi-account-clock</v-icon>
            <span class="menu-tab">Request Connection</span></v-tab
          >
          <v-tab href="#tab-2"
            ><v-icon class="mr-2">mdi-account-check</v-icon
            ><span class="menu-tab">My Connection</span></v-tab
          >
          <v-tab href="#tab-3"
            ><v-icon class="mr-2">mdi-account-cancel</v-icon
            ><span class="menu-tab">Blocked</span></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-toolbar dense flat class="my-3">
              <h3>Request Connection</h3>
              <v-spacer></v-spacer>
              <!-- <v-row justify="end">
                <v-col cols="10" md="6"
                  ><v-text-field
                    solo
                    label="cari"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="10" md="4">
                  <v-text-field
                    solo
                    label="Filter"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-toolbar>
            <v-card v-if="request" class="my-2" elevation="0">
              <v-list three-line>
                <template v-for="(item, index) in request.data">
                  <v-divider :key="`${index}-slash`"></v-divider>
                  <v-list-item :key="index">
                    <v-list-item-avatar>
                      <v-img
                        :src="
                          item.photo_profile
                            ? `${env}/upload/photo_profile/${item.user_id}/${item.photo_profile}`
                            : `${env}/images/logo.png`
                        "
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <p class="ma-0">{{ item.nama_lengkap }}</p>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.username"
                      ></v-list-item-subtitle>
                      <div class="d-flex additional_">
                        <v-btn
                          outlined
                          rounded
                          color="green"
                          @click="aprove(item.id)"
                          class="mr-3 mb-1"
                          >Accept</v-btn
                        >
                        <div></div>
                        <v-btn
                          outlined
                          rounded
                          class="mb-1"
                          @click="reject(item.id)"
                          color="red"
                          >Deny</v-btn
                        >
                      </div>
                    </v-list-item-content>

                    <v-list-item-action class="action__">
                      <div class="action-button">
                        <div>
                          <v-btn
                            outlined
                            rounded
                            color="green"
                            @click="aprove(item.id)"
                            class="mr-3 mb-1"
                            >Accept</v-btn
                          >
                          <div></div>
                          <v-btn
                            outlined
                            rounded
                            class="mb-1"
                            @click="reject(item.id)"
                            color="red"
                            >Deny</v-btn
                          >
                        </div>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div
                v-if="!request.data.length"
                class="d-flex flex-column align-center"
              >
                <img src="../../assets/img/404.svg" height="150px" alt="404" />
                <h3>no connection request yet</h3>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2" v-if="friends">
            <v-toolbar dense flat>
              <h3>Connection</h3>
              <v-spacer></v-spacer>
              <!-- <v-row justify="end">
                <v-col cols="10" md="6"
                  ><v-text-field
                    solo
                    label="cari"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="10" md="4">
                  <v-text-field
                    solo
                    label="Filter"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-toolbar>
            <v-list three-line v-if="friends">
              <template v-for="(item, index) in friends.data">
                <v-divider :key="index"></v-divider>

                <v-list-item :key="item.title">
                  <v-list-item-avatar>
                    <v-img
                      :src="
                        item.photo_profile
                          ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="item.nama_lengkap"
                    ></v-list-item-title>
                    <!-- <v-list-item-subtitle
                      v-html="item.work"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-html="item.from"
                    ></v-list-item-subtitle> -->
                    <v-btn
                      outlined
                      rounded
                      color="blue"
                      class="additional_"
                      @click="toView(item)"
                      >Lihat Profile</v-btn
                    >
                  </v-list-item-content>

                  <v-list-item-action class="action__">
                    <v-btn outlined rounded color="blue" @click="toView(item)"
                      >Lihat Profile</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div
              v-if="!friends.data.length"
              class="d-flex flex-column align-center"
            >
              <img src="../../assets/img/404.svg" height="150px" alt="404" />
              <h3>you have no connection</h3>
            </div>
          </v-tab-item>
          <v-tab-item value="tab-3" v-if="blocked">
            <v-toolbar dense flat>
              <h3>Blocked</h3>
              <v-spacer></v-spacer>
              <!-- <v-row justify="end">
                <v-col cols="10" md="6"
                  ><v-text-field
                    solo
                    label="cari"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field
                ></v-col>
                <v-col cols="10" md="4">
                  <v-text-field
                    solo
                    label="Filter"
                    rounded
                    append-icon="mdi-magnify"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-toolbar>
            <v-list three-line>
              <template v-for="(item, index) in blocked.data">
                <v-divider :key="index"></v-divider>

                <v-list-item :key="item.id">
                  <!-- <v-list-item-avatar size="50">
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar> -->

                  <v-list-item-content>
                    <v-list-item-title
                      v-html="item.nama_lengkap"
                    ></v-list-item-title>
                    <!-- <v-list-item-subtitle
                      v-html="item.work"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-html="item.from"
                    ></v-list-item-subtitle> -->
                    <v-btn
                      outlined
                      rounded
                      class="additional_"
                      color="blue"
                      @click="unblock(item.id)"
                      >UNBLOCK</v-btn
                    >
                  </v-list-item-content>

                  <v-list-item-action class="action__">
                    <v-btn
                      outlined
                      rounded
                      color="blue"
                      @click="unblock(item.id)"
                      >UNBLOCK</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div
              v-if="!blocked.data.length"
              class="d-flex flex-column align-center"
            >
              <img src="../../assets/img/404.svg" height="150px" alt="404" />
              <h3>you have no connection</h3>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "connection",
  computed: {
    ...mapState({
      profile: (state) => state.psycholog.myProfile,
      request: (state) => state.friendship.request,
      friends: (state) => state.friendship.friends,
      blocked: (state) => state.friendship.blocked,
      env: (state) => state.API_URL,
      myid: (state) => state.id,
      dummy: (state) => state.dummy,
    }),
  },
  data() {
    return {
      tab: "tab-1",
      loading: false,
      limit: 3,
    };
  },
  mounted() {
    this.fetchBlocked();
    this.fetchRequest();
    this.fetchFriends();
  },
  methods: {
    fetchFriends() {
      let data = {
        page: 1,
        limit: this.limit,
      };
      this.loading = true;
      this.$store.dispatch("friendship/listFriends", data).then(() => {
        this.loading = false;
      });
    },
    fetchBlocked() {
      this.$store.dispatch("friendship/listBlocked");
    },
    fetchRequest() {
      let data = {
        page: 1,
        limit: this.limit,
      };
      this.$store.dispatch("friendship/listRequest", data);
    },
    connect(id) {
      this.loading = true;
      let data = new FormData();
      data.append("target_id", id);
      this.$store.dispatch("friendship/sendRequest", data).then((data) => {
        this.loading = false;
        console.log(data);
      });
    },

    aprove(id) {
      this.loading = true;
      let data = new FormData();
      data.append("request_id", id);
      this.$store.dispatch("friendship/aproveRequest", data).then((data) => {
        this.loading = false;
        console.log(data);
        this.fetchRequest();
        this.fetchFriends();
      });
    },

    reject(id) {
      this.loading = true;
      let data = new FormData();
      data.append("request_id", id);
      this.$store.dispatch("friendship/rejectRequest", data).then((data) => {
        this.loading = false;
        console.log(data);
        this.fetchRequest();
      });
    },

    unblock(id) {
      this.loading = true;
      let data = new FormData();
      data.append("target_id", id);
      this.$store.dispatch("friendship/unblockFriends", data).then(() => {
        this.loading = false;
        this.fetchBlocked();
      });
    },

    toView(item) {
      this.$router.push(`/pam/${item.id}`);
    },
  },
};
</script>

<style scoped>
.additional_ {
  display: none;
}

.action-button {
  display: flex;
}
@media (max-width: 576px) {
  .menu-tab {
    display: none;
  }
  .action-button {
    display: block;
  }
  .additional_ {
    display: block;
  }
  .action__ {
    display: none;
  }
}
@media (max-width: 768px) {
  .menu-tab {
    display: none;
  }
  .action-button {
    display: block;
  }
  .additional_ {
    display: block;
  }
  .action__ {
    display: none;
  }
}
</style>
